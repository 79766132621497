import React from "react";
import styles from "./paypal.scss";
import axios from "axios";
import {message} from "antd";

const modal = document.createElement("div")
modal.setAttribute("class", styles.payModal)
modal.addEventListener("click", () => modal.remove())

const wrap = document.createElement("div")
wrap.setAttribute("class", styles.wrap)
wrap.addEventListener("click", e => e.stopPropagation())
modal.appendChild(wrap)

const button = document.createElement("div")
button.setAttribute("id", "paypal-button")
wrap.appendChild(button)

export function payWithPaypal({data: postData, onSuccess, onCancel}) {
  document.body.appendChild(modal)
  window.paypal.Buttons({
    async createOrder(actions, b, c) {
      try {
        const resp = await axios({
          url: "/paypal/create",
          method: "post",
          data: postData,
        })
        const {code, errMsg, data} = resp.data;
        if (code) {
          throw new Error(errMsg)
        }
        return data?.externalOrderId
      } catch (error) {
        console.error(error)
        return null
      }
    },
    async onApprove(data, actions) {
      console.log(data, actions)
      const {orderID} = data
      const resp = await axios({
        url: "/paypal/capture",
        method: "post",
        data: {
          externalOrderId: orderID,
        },
      })
      const {code, errMsg} = resp.data;
      if (code) {
        throw new Error(errMsg)
      }
      modal.remove()
      onSuccess && onSuccess({externalOrderId: orderID, payMethod: 2})
    },
    async onCancel(data) {
      const {orderID} = data
      const resp = await axios({
        url: "/paypal/cancel",
        method: "post",
        data: {
          externalOrderId: orderID,
        }
      })
      onCancel && onCancel()
      const {code, errMsg} = resp.data;
      if (code) {
        throw new Error(errMsg)
      }
    },
    onError(error) {
      console.error(error)
      message.error({duration: 2, content: "Network error"})
    },
  }).render("#paypal-button")
}