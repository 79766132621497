import React, { useEffect, useState } from "react";
import styles from "./paper.scss";
import { get, post, WS_HOST } from "~/request";
import {
  Alert,
  Button,
  Collapse,
  Input,
  Spin,
  Tooltip,
  Typography,
  message,
} from "antd";
import moment from "moment/moment";
import { QuestionCircleOutlined, RightOutlined } from "@ant-design/icons";
import * as echarts from "echarts/core";
import { innovateKey } from "~/constant";
import Md from "~/components/md/md";
import { exportPDF } from "~/common/exportPDF";
import PayModal from "~/components/pay/payModal";
import ReactMarkdown from "react-markdown";
import { ossServer } from "../../constant";
import { store } from "~/store";
import { payWithFree } from "~/components/pay/free";
import axios from "axios";
window.lang = "en";
const subjects = [
  "Materials Science",
  "Chemistry",
  "Computer Science",
  "Physics",
  "Biology",
];

export default function Paper({ user, example, doi, lang }) {
  const [info, setInfo] = React.useState(null);
  const [simpleReport, setSimpleReport] = React.useState(null);
  const [fullReport, setFullReport] = React.useState(null);
  const pdfRef = React.useRef();
  const [loading, setLoading] = React.useState(true);
  const [payModalVisible, setPayModalVisible] = React.useState(false);
  const [exporting, setExporting] = React.useState(false);
  const [navIds, setNavIds] = useState([]);

  const ws = React.useRef(null);

  const onWsMessage = (e) => {
    const data = JSON.parse(e.data);
    if (data?.doi === doi) {
      setInfo(data);
    }
  };

  const initWs = () => {
    if (!window.userId) {
      return;
    }
    const _ws = new WebSocket(`${WS_HOST}/ws/search/${window.userId}`);
    _ws.addEventListener("message", onWsMessage);
    _ws.addEventListener("close", (e) => {
      const { code } = e;
      if (code !== 1000) {
        setTimeout(() => (ws.current = initWs()), 5000);
      }
    });

    return _ws;
  };
  const paperCallback = () => {
    axios({
      url: `https://www.knowledgeworks.chat/api/paper_analysis/?doi=${doi}`,
      headers: { "Content-Type": "application/json" },
      method: "get",
    }).then(({ data: res }) => {
      if (res.summary && res.metadata.pub_date) {
        let y = moment(res.metadata.pub_date).format("YYYY");
        let m = moment(res.metadata.pub_date).format("MM");
        if (
          y == 2024 &&
          m > 4 &&
          m < 7 &&
          subjects.includes(res.summary.subject)
        ) {
          post({
            url: "/racing/join",
            data: {
              doi,
            },
            timeout: 600000,
            ignoreError: true,
            onSuccess: (res) => {
              console.log(res);
              setIsActive(false);
              message.success("Successfully joined the competition.");
            },
            onFail: (error, msg) => {
              console.log(error, msg);
              if (error == 10140) {
                message.info(msg);
              }
              setIsActive(false);
              if (error.code == "ECONNABORTED") {
                message.warning("nonsupport!");
              }
            },
          });
        }
      }
    });
  }
  const [isActive, setIsActive] = React.useState(false);
  const showPayModal = () => {
    if (isActive) return;
    setIsActive(true);
    get({
      url: "/search/get/count",
      onSuccess(times) {
        if (times > 0) {
          get({
            url: "/search/minus",
            onSuccess(res) {
              if (res >= 0) {
                payWithFree({
                  data: {
                    doi,
                    payItem: 1
                  },
                  onSuccess: paperCallback,
                })
                store.dispatch({
                  type: "common",
                  freeTimes: res
                });
              }
            }
          })
        } else {
          setPayModalVisible(true);
        }
      }
    })


  };

  const getReport = () => {
    // 获取分析报告
    setLoading(true);
    post({
      url: example ? "/search/example" : "/search",
      data: {
        doi,
      },
      ignoreError: true,
      timeout: 60000,
      onSuccess: (data) => {
        setLoading(false);
        setInfo(data);
      },
      onFail: (error) => {
        console.log(error);
        if (error == 10080) {
          post({
            url: "/user/disclaimer/confirm",
            data: {},
            onSuccess: (res) => {
              console.log(res);
              getReport();
            },
          });
        }
      },
    });
  };

  useEffect(() => {
    getReport();
    ws.current = initWs();

    return () => {
      if (ws.current) {
        ws.current.removeEventListener("close", onWsMessage);
        ws.current.close();
      }
    };
  }, []);

  React.useEffect(() => {
    if (info) {
      if (info.fullResult && !fullReport) {
        setLoading(true);
        get({
          url: info.fullResult,
          isJson: true,
          onSuccess: (res) => {
            setFullReport(res);
            setLoading(false);
          },
        });
      } else if (info.simpleResult && !simpleReport) {
        setLoading(true);
        get({
          url: info.simpleResult,
          isJson: true,
          onSuccess: (res) => {
            setSimpleReport(res);
            setLoading(false);
          },
        });
      }
      let arr = [];
      for (let i = 0; i < 4; i++) {
        arr.push(Math.random().toString());
      }
      arr.length != 0 && setNavIds([...arr]);
    }
  }, [info]);

  const onExportPDF = () => {
    setExporting(true);
    setTimeout(() => {
      exportPDF(info.doi, pdfRef.current, user?.email || "Spider Matrix", () =>
        setExporting(false)
      );
    }, 1000);
  };

  return (
    <div className={styles.analysis}>
      <div
        className={styles.report}
        style={{
          backgroundImage: `url(${ossServer}/web/paperBg.png)`,
          backgroundSize: "cover",
        }}
      >
        {fullReport && (
          <FullReport
            doi={doi}
            info={info}
            fullReport={fullReport}
            pdfRef={pdfRef}
            showPayModal={showPayModal}
            example={example}
            user={user}
            onExportPDF={onExportPDF}
            exporting={exporting}
            navIds={navIds}
            lang={lang}
          />
        )}
        {!fullReport && simpleReport && (
          <SimpleReport
            info={info}
            simpleReport={simpleReport}
            showPayModal={showPayModal}
            navIds={navIds}
            lang={lang}
          />
        )}
      </div>
      <Spin spinning={exporting || loading} fullscreen />
      {!example && (
        <PayModal
          payItem={1}
          postData={{ doi }}
          onSuccess={paperCallback}
          visible={payModalVisible}
          setVisible={setPayModalVisible}
          lang={lang}
        />
      )}
    </div>
  );
}

const SimpleReport = ({ simpleReport, info, showPayModal, navIds, lang }) => {
  return (
    <div>
      <div className={styles.paperTitle}>
        <span className={styles.doi}>
          DOI:&nbsp;{simpleReport.metadata.doi}
        </span>
        <h1>{simpleReport.metadata.title}</h1>
        <Typography.Paragraph>
          {simpleReport.metadata.author &&
            simpleReport.metadata.author.map((it, idx) => {
              return (
                it.full_name +
                (idx + 1 != simpleReport.metadata.author.length ? ", " : "")
              );
            })}
        </Typography.Paragraph>
        <Typography.Paragraph>
          {!simpleReport.metadata.journal &&
            !simpleReport.metadata.pub_date &&
            !simpleReport.metadata.title &&
            "Sorry, this article is new and lacks detailed info, but doesn't hinder report generation."}
          {simpleReport.metadata.journal &&
            simpleReport.metadata.journal + "; "}
          {simpleReport.metadata.pub_date &&
            moment(simpleReport.metadata.pub_date).format("YYYY-MM-DD")}
        </Typography.Paragraph>
      </div>
      <div className={styles.paperCon} style={{ padding: 20 }}>
        <NavInfo checked={1} navIds={navIds} lang={lang} />
        <div id={navIds[0]} className={styles.cons}>
          <BasicInfo report={simpleReport} lang={lang} />
        </div>

        <NavInfo checked={2} navIds={navIds} lang={lang} />
        <div id={navIds[1]} className={styles.cons}>
          <DimensionalAnalysis
            info={info}
            report={simpleReport}
            full={false}
            showPayModal={showPayModal}
            lang={lang}
          />
        </div>
      </div>
    </div>
  );
};

const InnovationMethod = ({ method, lang }) => {
  const [text, setText] = React.useState("");

  React.useEffect(() => {
    let initialText = "";
    if (method.method) {
      initialText += method.method;
      if (!method.method.endsWith(".")) {
        initialText += ".";
      }
      initialText += " ";
    }
    if (method.detail) {
      initialText += method.detail;
    }
    setText(initialText);
  }, []);

  const innovate = () => {
    const method = text.trim();
    console.log(method, text);
    if (method) {
      localStorage.setItem(
        innovateKey,
        JSON.stringify({ type: "paper", method })
      );
      setTimeout(() => (window.location.href = "/innovate"), 100);
    }
  };

  return (
    <div className={styles.innovation}>
      <Input.TextArea
        className={styles.innovationInput}
        rows={8}
        placeholder=""
        value={text}
        onChange={(e) => setText(e.target.value)}
      />
      <div className={styles.innovationBtns}>
        <GeneratePatent lang={lang} idea={text.trim()} />
        <Button
          type="primary"
          onClick={innovate}
          className={styles.innovationBtn}
        >
          Innovate
        </Button>
      </div>
    </div>
  );
};

const InnovationMethods = ({ doi, lang, abs }) => {
  const [innovationMethods, setInnovationMethods] = React.useState([]);

  React.useEffect(() => {
    get({
      url: `/innovation/paper/methods?doi=${encodeURIComponent(doi)}`,
      ignoreError: true,
      onSuccess: (methods) => {
        setInnovationMethods(methods);
      },
      onFail: (error, msg) => {
        if (error == 10050) {
          console.log(msg);
        } else {
          message.warning(msg);
        }
      },
    });
  }, []);

  return (
    <React.Fragment>
      {innovationMethods.length === 0 ? (
        <div className={styles.innovationBtns}>
          <InnovationBtns doi={doi} lang={lang} abs={abs} />
        </div>
      ) : (
        <>
          <h2>{lang == "en" ? "Extracted Methods" : "提取方法"}</h2>
          {innovationMethods.map((method, idx) => (
            <InnovationMethod method={method} key={idx} lang={lang} />
          ))}
        </>
      )}
    </React.Fragment>
  );
};

// generatePatent innovate 无methods情况下
const InnovationBtns = ({ doi, lang, abs }) => {
  return (
    <div className={styles.innovationBtns1}>
      <GeneratePatent lang={lang} idea={abs} />
      <Button
        onClick={() => {
          localStorage.setItem(
            innovateKey,
            JSON.stringify({ type: "paper", doi })
          );
          setTimeout(() => (window.location.href = "/innovate"), 100);
        }}
        className={styles.innovationBtn}
      >
        {lang == "en" ? "Innovate" : "创新"}
      </Button>
    </div>
  );
};

const GeneratePatent = ({ lang, idea }) => {
  const generate = () => {
    localStorage.setItem(innovateKey, JSON.stringify({ type: "paper", idea }));
    setTimeout(() => (window.location.href = "/generate"), 100);
  };
  return (
    <Button onClick={generate}>
      {lang == "en" ? "Generate Patent" : "生成专利"}
    </Button>
  );
};

const FullReport = ({
  doi,
  fullReport,
  info,
  pdfRef,
  showPayModal,
  example,
  exporting,
  onExportPDF,
  navIds,
  lang,
}) => {
  const [similarPapers, setSimilarPapers] = React.useState({
    inner: [],
    outer: [],
    others: [],
  });
  const [checkedPaper, setCheckedPaper] = React.useState(fullReport); // 当前选中论文
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768); // 相应式

  const handleResize = () => {
    setIsMobile(window.innerWidth < 768);
  };

  React.useEffect(() => {
    console.log(fullReport);
    if (fullReport.similar_papers) {
      const similarPapers = {
        inner: [],
        outer: [],
        others: [],
      };
      const currentYear = moment(fullReport.metadata.pub_date).format("YYYY");
      const sorted = fullReport.similar_papers.sort((a, b) => {
        return b.similarity - a.similarity;
      });

      // 深度相关论文
      sorted.forEach((paper) => {
        const year = moment(paper.metadata.pub_date).format("YYYY");
        if (year >= currentYear - 2 && year < currentYear) {
          if (similarPapers.inner.length < 4) {
            similarPapers.inner.push(paper);
          } else {
            similarPapers.others.push(paper);
          }
        } else if (year >= currentYear - 10 && year < currentYear - 2) {
          if (similarPapers.outer.length < 8) {
            similarPapers.outer.push(paper);
          } else {
            similarPapers.others.push(paper);
          }
        } else {
          similarPapers.others.push(paper);
        }
      });
      setSimilarPapers(similarPapers);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <React.Fragment>
      <div
        ref={pdfRef}
        style={{
          backgroundImage: exporting
            ? "linear-gradient(to top, #f7f5f5 0%, #e3eeff 99%, #e3eeff 100%)"
            : undefined,
        }}
      >
        <div className={styles.paperTitle}>
          <span className={styles.doi}>
            DOI:&nbsp;{fullReport.metadata.doi}
          </span>
          <h1>{fullReport.metadata.title}</h1>
          <Typography.Paragraph>
            {fullReport.metadata.author &&
              fullReport.metadata.author.map((it, idx) => {
                return (
                  it.full_name +
                  (idx + 1 != fullReport.metadata.author.length ? ", " : "")
                );
              })}
          </Typography.Paragraph>
          <Typography.Paragraph>
            {fullReport.metadata.journal};&nbsp;
            {moment(fullReport.metadata.pub_date).format("YYYY-MM-DD")}
            &nbsp;&nbsp;&nbsp;&nbsp;
            {!exporting && (
              <InnovationBtns
                doi={doi}
                lang={lang}
                abs={fullReport.metadata.abstract}
              />
            )}
          </Typography.Paragraph>
        </div>
        <div className={styles.paperCon} style={{ padding: 20 }}>
          <NavInfo checked={1} navIds={navIds} lang={lang} />
          <div id={navIds[0]} className={styles.cons}>
            <BasicInfo report={fullReport} lang={lang} />
          </div>
          <NavInfo checked={2} navIds={navIds} lang={lang} />
          <div id={navIds[1]} className={styles.cons}>
            <DimensionalAnalysis
              info={info}
              report={fullReport}
              full={true}
              showPayModal={showPayModal}
              lang={lang}
            />
          </div>
          <NavInfo checked={3} navIds={navIds} lang={lang} />
          <div id={navIds[2]} className={styles.cons}>
            <ImprovementSuggestions report={fullReport} />
          </div>
          <NavInfo checked={4} navIds={navIds} lang={lang} />
          <div id={navIds[3]} className={styles.cons}>
            <Typography.Paragraph>
              {!exporting ? (
                <div
                  style={{
                    display: isMobile ? "block" : "flex",
                    alignItems: "start",
                  }}
                >
                  <ThroughRef
                    paper={fullReport}
                    similarPapers={similarPapers}
                    setCheckedPaper={setCheckedPaper}
                  />
                  <div className={styles.similarPapers}>
                    <CheckedPaper
                      checkedPaper={checkedPaper}
                      exporting={exporting}
                      lang={lang}
                    />
                    <OtherSimilarPapers
                      exporting={exporting}
                      similarPapers={similarPapers}
                      allSimilarPapers={fullReport.similar_papers}
                      lang={lang}
                    />
                  </div>
                </div>
              ) : (
                <OtherSimilarPapers
                  exporting={exporting}
                  similarPapers={similarPapers}
                  allSimilarPapers={fullReport.similar_papers}
                  lang={lang}
                />
              )}
            </Typography.Paragraph>
          </div>
          {!exporting && (
            <InnovationMethods
              doi={doi}
              lang={lang}
              abs={fullReport.metadata.abstract}
            />
          )}
        </div>
      </div>
      {!example && (
        <div className={styles.btns}>
          <Button onClick={onExportPDF}>
            {lang == "en" ? "Export PDF" : "导出PDF"}
          </Button>
        </div>
      )}
    </React.Fragment>
  );
};

const OtherSimilarPapers = ({
  exporting,
  similarPapers,
  allSimilarPapers,
  lang,
}) => {
  const [activeKey, setActiveKey] = React.useState([]);
  const exportingActiveKey = allSimilarPapers.map((_, idx) => `${idx + 1}`);
  return (
    <React.Fragment>
      <h4>
        {lang == "en"
          ? "The rest of the depth related papers"
          : "其余深度相关论文"}
        <span style={{ color: "grey" }}>
          ({lang == "en" ? "section" : "部分"})
        </span>
        &nbsp;
        <Tooltip
          title={
            lang == "en"
              ? "High dimensional vector similarity matching based on idea"
              : "基于Idea的高维向量相似度匹配"
          }
        >
          <QuestionCircleOutlined />
        </Tooltip>
      </h4>
      <Collapse
        activeKey={exporting ? exportingActiveKey : activeKey}
        onChange={(keys) => setActiveKey(keys)}
        className={styles.bot}
        ghost
        expandIconPosition="end"
        size="large"
        expandIcon={({ isActive }) => (
          <RightOutlined
            style={{ fontSize: 20, color: "#1A65FF" }}
            rotate={isActive ? 90 : 0}
          />
        )}
      >
        {(exporting ? allSimilarPapers : similarPapers.others.slice(0, 8)).map(
          (paper, idx) => (
            <Collapse.Panel
              key={`${idx + 1}`}
              header={
                <div className={styles.colHeader}>
                  <span>
                    {paper.similarity ? paper.similarity.toFixed(2) : "本文"}
                  </span>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: paper.metadata.title,
                    }}
                  />
                </div>
              }
            >
              <SimilarityExamItems item={paper.metadata} lang={lang} />
            </Collapse.Panel>
          )
        )}
      </Collapse>
    </React.Fragment>
  );
};

const Circle = ({ paper, className, onClick, checked, center = false }) => {
  return (
    <div
      className={className}
      onClick={() => onClick(paper)}
      style={{
        backgroundColor: checked && "#0070ef",
        color: checked && "white",
      }}
    >
      <span>
        {center ? (
          <span>This article</span>
        ) : (
          <span style={{ color: checked && "white" }}>
            {paper.similarity.toFixed(2)}
          </span>
        )}
        <br />
        {moment(paper.metadata.pub_date).format("YYYY")}
      </span>
    </div>
  );
};

const ThroughRef = ({ paper, similarPapers, setCheckedPaper }) => {
  const [checkedTitle, setCheckedTitle] = React.useState(paper.metadata.title);

  // 获取选中文章信息
  const selectPaper = (paper) => {
    setCheckedTitle(paper.metadata.title);
    setCheckedPaper(paper);
  };

  return (
    <React.Fragment>
      <div className={styles.interaction}>
        <div className={styles.ring}>
          <div className={styles.innerCircle}>
            <Circle
              className={styles.center}
              onClick={selectPaper}
              checked={checkedTitle === paper.metadata.title}
              paper={paper}
              center={true}
            />
            {similarPapers.inner &&
              similarPapers.inner.length > 0 &&
              similarPapers.inner.map((paper, idx) => (
                <Circle
                  className={styles.inner}
                  onClick={selectPaper}
                  key={idx}
                  checked={checkedTitle === paper.metadata.title}
                  paper={paper}
                />
              ))}
          </div>
          {similarPapers.outer &&
            similarPapers.outer.length > 0 &&
            similarPapers.outer.map((paper, idx) => (
              <Circle
                className={styles.outer}
                onClick={selectPaper}
                key={idx}
                checked={checkedTitle === paper.metadata.title}
                paper={paper}
              />
            ))}
        </div>
      </div>
    </React.Fragment>
  );
};

const CheckedPaper = ({ checkedPaper, exporting, lang }) => {
  const [activeKey, setActiveKey] = React.useState([]);
  return (
    <React.Fragment>
      <h4>{lang == "en" ? "Selected paper" : "选中论文"}</h4>
      <Collapse
        activeKey={exporting ? ["1"] : activeKey}
        onChange={(keys) => setActiveKey(keys)}
        ghost
        expandIconPosition="end"
        accordion={true}
        size="large"
        expandIcon={({ isActive }) => (
          <RightOutlined
            style={{ fontSize: 20, color: "#1A65FF" }}
            rotate={isActive ? 90 : 0}
          />
        )}
        style={{
          background: "rgba(0, 112, 239, 0.08)",
          borderColor: "#1F94FA",
        }}
      >
        <Collapse.Panel
          key="1"
          header={
            <div className={styles.colHeader}>
              <span>
                {checkedPaper.similarity
                  ? checkedPaper.similarity.toFixed(2)
                  : "本文"}
              </span>
              <div
                dangerouslySetInnerHTML={{
                  __html: checkedPaper.metadata.title,
                }}
              />
            </div>
          }
        >
          <SimilarityExamItems item={checkedPaper.metadata} lang={lang} />
        </Collapse.Panel>
      </Collapse>
    </React.Fragment>
  );
};

const ImprovementSuggestions = ({ report }) => {
  const handleKey = (key) => {
    let tempKey = key.charAt(0).toUpperCase() + key.slice(1);
    return key.includes("_") ? tempKey.replace("_", " ") : tempKey;
  };

  return (
    <Typography.Paragraph style={{ fontSize: 16 }}>
      {report.rating.improve_suggest && (
        <>
          <Typography.Title level={4}>Improvement Suggestions</Typography.Title>
          {report.rating.improve_suggest}
        </>
      )}
      <Typography.Title level={4}>Summary</Typography.Title>
      {/* {Object.keys(report.summary).map((key) => {
        return (
          <div key={key}>
            {key !== "summary" && (
              <>
                <Typography.Text strong style={{ fontSize: 18 }}>
                  {handleKey(key)}
                </Typography.Text>
                :&nbsp;
                {report.summary[key] == ""
                  ? report.rating[key].reason
                  : report.summary[key]}
              </>
            )}
          </div>
        );
      })} */}
      <Typography.Text strong style={{ fontSize: 17 }}>
        Overall
      </Typography.Text>
      :&nbsp;{report.rating.overall}
      <br />
      <Typography.Text strong style={{ fontSize: 17 }}>
        Subject
      </Typography.Text>
      :&nbsp;{report.summary.subject}
      <br />
      <Typography.Text strong style={{ fontSize: 17 }}>
        Summary
      </Typography.Text>
      :&nbsp;{report.summary.summary}
    </Typography.Paragraph>
  );
};

const BasicInfo = ({ report, lang }) => {
  return (
    <Typography>
      <Typography.Title level={4}>
        {lang == "en" ? "Title" : "标题"}
      </Typography.Title>
      <Typography.Paragraph style={{ fontSize: 16 }}>
        {report.metadata.title}
      </Typography.Paragraph>
      <Typography.Title level={4}>
        {lang == "en" ? "Abstract" : "摘要"}
      </Typography.Title>
      <Typography.Paragraph style={{ fontSize: 16 }}>
        {report.metadata.abstract}
      </Typography.Paragraph>
      <div className={styles.details}>
        {report.metadata.author && (
          <div>
            <Typography.Title level={4}>
              {lang == "en" ? "Authors" : "作者"}
            </Typography.Title>
            <Typography.Paragraph style={{ fontSize: 16 }}>
              {report.metadata.author.map((it, idx) => {
                return (
                  it.full_name +
                  (idx + 1 != report.metadata.author.length ? ", " : "")
                );
              })}
            </Typography.Paragraph>
          </div>
        )}
        <div>
          <Typography.Title level={4}>DOI</Typography.Title>
          <Typography.Paragraph style={{ fontSize: 16 }}>
            {report.metadata.doi}
          </Typography.Paragraph>
        </div>
        {report.metadata.pub_date && (
          <div>
            <Typography.Title level={4}>
              {lang == "en" ? "Publication date" : "出版日期"}
            </Typography.Title>
            <Typography.Paragraph style={{ fontSize: 16 }}>
              {moment(report.metadata.pub_date).format("YYYY-MM-DD")}
            </Typography.Paragraph>
          </div>
        )}
        <div>
          <Typography.Title level={4}>
            {lang == "en" ? "Journal" : "期刊"}
          </Typography.Title>
          <Typography.Paragraph style={{ fontSize: 16 }}>
            {report.metadata.journal || ""}
          </Typography.Paragraph>
        </div>
        <div>
          <Typography.Title level={4}>
            {lang == "en" ? "Publisher" : "出版社"}
          </Typography.Title>
          <Typography.Paragraph style={{ fontSize: 16 }}>
            {report.metadata.publisher || ""}
          </Typography.Paragraph>
        </div>
        <div>
          <Typography.Title level={4}>
            {lang == "en" ? "Cited-by counts" : "引用次数"}
          </Typography.Title>
          <Typography.Paragraph style={{ fontSize: 16 }}>
            {report.metadata.citations || "N/A"}
          </Typography.Paragraph>
        </div>
        <div>
          <Typography.Title level={4}>
            {lang == "en" ? "Journal Impact Factor" : "期刊影响因子"}
          </Typography.Title>
          <Typography.Paragraph style={{ fontSize: 16 }}>
            {report.metadata.impact_factor || "N/A"}
          </Typography.Paragraph>
        </div>
        <div>
          <Typography.Title level={4}>
            {lang == "en" ? "JCR Quartile" : "JCR分区"}
          </Typography.Title>
          <Typography.Paragraph style={{ fontSize: 16 }}>
            {report.metadata.jcr_quantile || "N/A"}
          </Typography.Paragraph>
        </div>
      </div>
    </Typography>
  );
};

const drawChart = (dom, data, isPay) => {
  let myChart = echarts.getInstanceByDom(dom); //有的话就获取已有echarts实例的DOM节点。
  if (myChart == null) {
    // 如果不存在，就进行初始化。
    myChart = echarts.init(dom);
  }
  //此处省略数据处理过程
  const option = {
    angleAxis: {
      startAngle: 0,
    },
    radiusAxis: {
      type: "value",
      min: 0,
      max: isPay ? 100 : null,
      z: 1,
    },
    polar: {
      radius: 100,
    },
    radar: {
      shape: "circle",
      radius: 100,
      color: "black",
      indicator: [
        {
          name: "Problem",
          max: 100,
        },
        {
          name: "Theoretical Method",
          max: 100,
        },
        {
          name: "Practical Method",
          max: 100,
        },
        {
          name: "Effects",
          max: 100,
        },
        {
          name: "Conclusion",
          max: 100,
        },
      ],
      axisName: {
        color: "black",
      },
      nameGap: 20,
    },
    series: [
      {
        name: "Five-dimensional analysis",
        type: "radar",
        data: data,
        label: {
          show: isPay,
        },
        areaStyle: {
          color: "#0068c9",
          opacity: 0.2,
        },
      },
    ],
  };
  option && myChart.setOption(option);
};

const DimensionalAnalysis = ({ report, info, full, showPayModal, lang }) => {
  const [reportText, setReportText] = useState();
  const draw = () => {
    if (report) {
      const chartDom = document.getElementById("quintuple");
      if (chartDom) {
        drawChart(
          chartDom,
          [
            {
              value: [
                report.rating.question.score,
                report.rating.theoretical_method.score,
                report.rating.practical_method.score,
                report.rating.effects.score,
                report.rating.conclusion.score,
              ],
            },
          ],
          !!info.fullResult
        );
      }
    }
  };
  React.useEffect(() => {
    // setTimeout(draw, 300);
    // 去除title部分
    if (report && report.report) {
      let txt = lang == "en" ? report.report_ENG : report.report;
      let cleanedText = txt.replace(/^## .*(?:\n|$)/gm, "");
      setReportText(cleanedText);
    }
  }, [lang, report]);
  return (
    <Typography className={styles.fiveImg}>
      {!full && (
        <div>
          <div style={{ height: 300, filter: !full && "blur(2px)" }}>
            <div
              id="quintuple"
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            />
          </div>
          <div className={styles.shade}>
            {info.fullSupport === 1 && !info.payId && (
              <Button onClick={showPayModal} type="primary">
                View the full report
              </Button>
            )}
            {info.fullSupport === 2 && (
              <Alert
                type="error"
                message={
                  lang == "en"
                    ? "This paper does not yet support novelty report generation. We are working on it."
                    : "该论文暂不支持生成创新性报告，我们正在努力开发。"
                }
                banner
              />
            )}
            {(info.payId || !info.fullSupport) && (
              <Spin
                tip={
                  lang == "en"
                    ? "Analyzing the content of the paper... Please wait patiently as this process may take about 10 minutes."
                    : "正在分析文章内容，请耐心等待。此过程可能需要约10分钟。"
                }
                style={{ marginTop: "-60px" }}
              >
                <div style={{ width: "300px" }} />
              </Spin>
            )}
          </div>
        </div>
      )}
      {full && reportText && <ReactMarkdown>{reportText}</ReactMarkdown>}
    </Typography>
  );
};

const ExamItems = ({ item }) => {
  return (
    <Typography>
      {item.abstract && (
        <React.Fragment>
          <Typography.Title level={4}>Abstract</Typography.Title>
          <Typography.Paragraph style={{ fontSize: 16 }}>
            {item.abstract}
          </Typography.Paragraph>
        </React.Fragment>
      )}
      <Typography.Paragraph style={{ fontSize: 16 }}>
        <Typography.Text strong style={{ fontSize: 18 }}>
          DOI:
        </Typography.Text>
        &nbsp;{item.doi}
      </Typography.Paragraph>
      {item.author.length > 0 && (
        <React.Fragment>
          <Typography.Title level={4}>Authors</Typography.Title>
          <Typography.Paragraph style={{ fontSize: 16 }}>
            {item.author.map((it, idx) => {
              return it.full_name + (idx + 1 != item.author.length ? ", " : "");
            })}
          </Typography.Paragraph>
        </React.Fragment>
      )}
      <div className={styles.details}>
        {item.pub_date && (
          <div>
            <Typography.Title level={4}>Publication date</Typography.Title>
            <Typography.Paragraph style={{ fontSize: 16 }}>
              {moment(item.pub_date).format("YYYY-MM-DD")}
            </Typography.Paragraph>
          </div>
        )}
        <div>
          <Typography.Title level={4}>Journal</Typography.Title>
          <Typography.Paragraph style={{ fontSize: 16 }}>
            {item.journal}
          </Typography.Paragraph>
        </div>
        <div>
          <Typography.Title level={4}>Publisher</Typography.Title>
          <Typography.Paragraph style={{ fontSize: 16 }}>
            {item.publisher}
          </Typography.Paragraph>
        </div>
      </div>
    </Typography>
  );
};

const SimilarityExamItems = ({ item, lang }) => {
  return (
    <Typography>
      <div className={styles.tips}>
        <span>DOI:&nbsp;{item.doi}</span>
        <span>
          {lang == "en" ? "Publication date" : "出版日期"}:&nbsp;
          {moment(item.pub_date).format("YYYY-MM-DD")}
        </span>
      </div>
      {item.abstract && (
        <React.Fragment>
          <Typography.Title level={4}>
            {lang == "en" ? "Abstract" : "摘要"}
          </Typography.Title>
          <Typography.Paragraph style={{ fontSize: 16 }}>
            {item.abstract}
          </Typography.Paragraph>
        </React.Fragment>
      )}
      {item.author.length > 0 && (
        <React.Fragment>
          <Typography.Title level={4}>
            {lang == "en" ? "Authors" : "作者"}
          </Typography.Title>
          <Typography.Paragraph style={{ fontSize: 16 }}>
            {item.author.map((it, idx) => {
              return it.full_name + (idx + 1 != item.author.length ? ", " : "");
            })}
          </Typography.Paragraph>
        </React.Fragment>
      )}
      <Typography.Title level={4}>
        {lang == "en" ? "Journal" : "期刊"}
      </Typography.Title>
      <Typography.Paragraph style={{ fontSize: 16 }}>
        {item.journal}
      </Typography.Paragraph>
      <Typography.Title level={4}>
        {lang == "en" ? "Publisher" : "出版社"}
      </Typography.Title>
      <Typography.Paragraph style={{ fontSize: 16 }}>
        {item.publisher}
      </Typography.Paragraph>
    </Typography>
  );
};

const NavInfo = ({ checked, navIds, lang }) => {
  const navTabs = [
    {
      en: "Basic Article Info",
      cn: "文章基本信息",
    },
    {
      en: "Novelty Report",
      cn: "创新性报告",
    },
    {
      en: "Paper Summary & Suggestions",
      cn: "论文总结与建议",
    },
    {
      en: "Relevant papers",
      cn: "相关论文",
    },
  ];
  const smoothRoll = (id) => {
    let anchorElement = document.getElementById(id);
    if (anchorElement) {
      anchorElement.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <div className={styles.nav}>
      <div className={styles.left}>
        {navTabs.map((tab, index) => {
          return (
            <span
              key={Math.random()}
              className={checked == index + 1 ? styles.checked : undefined}
              onClick={() => smoothRoll(navIds[index])}
            >
              {lang == "en" ? tab.en : tab.cn}
            </span>
          );
        })}
      </div>
    </div>
  );
};
