import React, { useEffect, useState } from "react";
import styles from "./patent.scss";
import * as echarts from "echarts/core";
import {
  GridComponent,
  LegendComponent,
  PolarComponent,
  TitleComponent,
  TooltipComponent,
} from "echarts/components";
import { GraphChart, RadarChart, ScatterChart } from "echarts/charts";
import { UniversalTransition } from "echarts/features";
import { CanvasRenderer } from "echarts/renderers";
import { get, post, WS_HOST } from "~/request";
import { Alert, Button, Collapse, Spin, Typography } from "antd";
import "vditor/dist/index.css";
import Md from "~/components/md/md";
import { exportPDF } from "~/common/exportPDF";
import moment from "moment/moment";
import PayModal from "~/components/pay/payModal";
import { innovateKey } from "~/constant";
import { ossServer } from "../../constant";
import Gpt from "../../components/gpt/gpt";

echarts.use([
  TitleComponent,
  LegendComponent,
  RadarChart,
  CanvasRenderer,
  PolarComponent,
  TooltipComponent,
  GridComponent,
  GraphChart,
  CanvasRenderer,
  UniversalTransition,
  ScatterChart,
]);

function drawChart(dom, data, isPay, lang) {
  let myChart = echarts.getInstanceByDom(dom); //有的话就获取已有echarts实例的DOM节点。
  if (myChart == null) {
    // 如果不存在，就进行初始化。
    myChart = echarts.init(dom);
  }
  //此处省略数据处理过程
  let option = {
    angleAxis: {
      startAngle: 0,
    },
    radiusAxis: {
      type: "value",
      min: 0,
      z: 1,
    },
    polar: {
      radius: 100,
    },
    radar: {
      shape: "circle",
      radius: 100,
      color: "black",
      indicator: [
        {
          name: `${lang === "en" ? "Novelty" : "新颖性"}（25）`,
          max: 25,
        },
        {
          name: `${lang === "en" ? "Practicality" : "实用性"}（25）`,
          max: 25,
        },
        {
          name: `${lang === "en" ? "Clarity" : "明确性"}（20）`,
          max: 20,
        },
        {
          name: `${lang === "en" ? "Protection" : "权利保护"}（15）`,
          max: 15,
        },
        {
          name: `${lang === "en" ? "Market" : "市场"}（15）`,
          max: 15,
        },
      ],
      axisName: {
        color: "black",
      },
      nameGap: 20,
    },
    series: [
      {
        name: lang === "en" ? "Five-dimensional analysis" : "五维分析",
        type: "radar",
        data: data,
        areaStyle: {
          color: "#0068c9",
          opacity: 0.2,
        },
        label: {
          show: true,
        },
      },
    ],
  };
  option && myChart.setOption(option);
}

export default function Patent({ user, example, patent, lang }) {
  const [info, setInfo] = React.useState(null);
  const [simpleReport, setSimpleReport] = React.useState(null);
  const [fullReport, setFullReport] = React.useState(null);
  const pdfRef = React.useRef();
  const [loading, setLoading] = React.useState(true);
  const [payModalVisible, setPayModalVisible] = React.useState(false);
  const [exporting, setExporting] = React.useState(false);
  const [navIds, setNavIds] = useState([]);

  const ws = React.useRef(null);

  const onWsMessage = (e) => {
    const data = JSON.parse(e.data);
    if (data?.patent === patent) {
      setInfo(data);
    }
  };

  const initWs = () => {
    if (!window.userId) {
      return;
    }
    const _ws = new WebSocket(`${WS_HOST}/ws/search/${window.userId}`);
    _ws.addEventListener("message", onWsMessage);
    _ws.addEventListener("close", (e) => {
      const { code } = e;
      if (code !== 1000) {
        setTimeout(() => (ws.current = initWs()), 5000);
      }
    });

    return _ws;
  };

  const showPayModal = () => {
    setPayModalVisible(true);
  };

  const getReport = () => {
    // 获取分析报告
    setLoading(true);
    post({
      url: example ? "/search/example" : "/search",
      data: {
        patent,
      },
      ignoreError: true,
      timeout: 60000,
      onSuccess: (data) => {
        setLoading(false);
        setInfo(data);
      },
      onFail: (error) => {
        console.log(error);
        if (error == 10080) {
          post({
            url: "/user/disclaimer/confirm",
            data: {},
            onSuccess: (res) => {
              console.log(res);
              getReport()
            },
          });
        }
      },
    });
  };

  React.useEffect(() => {
    getReport();
    ws.current = initWs();
    return () => {
      if (ws.current) {
        ws.current.removeEventListener("close", onWsMessage);
        ws.current.close();
      }
    };
  }, []);

  React.useEffect(() => {
    if (info) {
      if (info.fullResult && !fullReport) {
        setLoading(true);
        get({
          url: info.fullResult,
          isJson: true,
          onSuccess: (res) => {
            setFullReport(res);
            setLoading(false);
          },
        });
      } else if (info.simpleResult && !simpleReport) {
        setLoading(true);
        get({
          url: info.simpleResult,
          isJson: true,
          onSuccess: (res) => {
            setSimpleReport(res);
            setLoading(false);
          },
        });
      }
      let arr = [];
      for (let i = 0; i < 2; i++) {
        arr.push(Math.random().toString());
      }
      arr.length != 0 && setNavIds([...arr]);
    }
  }, [info]);

  const onExportPDF = () => {
    setExporting(true);
    setTimeout(() => {
      exportPDF(
        info.patent,
        pdfRef.current,
        user?.email || "Spider Matrix",
        () => setExporting(false)
      );
    }, 1000);
  };

  return (
    <div className={styles.analysis}>
      <div
        className={styles.report}
        style={{
          backgroundImage: `url(${ossServer}/web/paperBg.png)`,
          backgroundSize: "cover",
        }}
      >
        {fullReport && (
          <FullReport
            patent={patent}
            lang={lang}
            info={info}
            fullReport={fullReport}
            pdfRef={pdfRef}
            showPayModal={showPayModal}
            example={example}
            user={user}
            onExportPDF={onExportPDF}
            exporting={exporting}
            navIds={navIds}
          />
        )}
        {!fullReport && simpleReport && (
          <SimpleReport
            lang={lang}
            info={info}
            simpleReport={simpleReport}
            showPayModal={showPayModal}
            navIds={navIds}
          />
        )}
      </div>
      <Spin spinning={exporting || loading} fullscreen />
      {!example && (
        <PayModal
          payItem={2}
          postData={{ patent }}
          onSuccess={() => window.location.reload()}
          visible={payModalVisible}
          setVisible={setPayModalVisible}
          lang={lang}
        />
      )}
    </div>
  );
}

const Innovation = ({ abstract, lang }) => {
  return (
    <div className={styles.innovation}>
      <Button
        onClick={() => {
          localStorage.setItem(
            innovateKey,
            JSON.stringify({ type: "patent", abstract: abstract })
          );
          setTimeout(() => (window.location.href = "/innovate"), 100);
        }}
        className={styles.innovationBtn}
      >
        {lang == "en" ? "Innovate" : "创新"}
      </Button>
    </div>
  );
};

const FullReport = ({
  patent,
  fullReport,
  info,
  pdfRef,
  showPayModal,
  example,
  exporting,
  onExportPDF,
  lang,
  navIds,
}) => {
  return (
    <React.Fragment>
      <div
        ref={pdfRef}
        style={{
          backgroundImage: exporting
            ? "linear-gradient(to top, #f7f5f5 0%, #e3eeff 99%, #e3eeff 100%)"
            : undefined,
        }}
      >
        <div className={styles.paperTitle}>
          <span className={styles.doi}>
            PATENT:&nbsp;{fullReport.metadata.id}
          </span>
          <h1>{fullReport.metadata.title}</h1>
          <Typography.Paragraph>
            {fullReport.metadata.author.map((it, idx) => {
              return (
                it.full_name +
                (idx + 1 != fullReport.metadata.author.length ? ", " : "")
              );
            })}
          </Typography.Paragraph>
          <Typography.Paragraph>
            {fullReport.metadata.owner};&nbsp;
            {fullReport.metadata.publication_type};&nbsp;
            {moment(fullReport.metadata.date_published).format("YYYY-MM-DD")}
          </Typography.Paragraph>
        </div>
        <div className={styles.paperCon} style={{ padding: 20 }}>
          <NavInfo checked={1} lang={lang} navIds={navIds} />
          <div id={navIds[0]} className={styles.cons}>
            <BasicInfo report={fullReport} lang={lang} />
          </div>

          <NavInfo checked={2} lang={lang} navIds={navIds} />
          <div id={navIds[1]} className={styles.cons}>
            <DimensionalAnalysis
              info={info}
              report={fullReport}
              full={true}
              showPayModal={showPayModal}
              lang={lang}
            />
          </div>
        </div>
        {!exporting && (
          <Innovation abstract={fullReport.metadata.abstract} lang={lang} />
        )}
      </div>
      <div className={styles.btns}>
        <Button onClick={onExportPDF}>
          {lang == "en" ? "Export PDF" : "导出PDF"}
        </Button>
      </div>
    </React.Fragment>
  );
};

const BasicInfo = ({ report, lang }) => {
  return (
    <Typography>
      <Typography.Title level={4}>
        {lang === "en" ? "Title" : "标题"}
      </Typography.Title>
      <Typography.Paragraph style={{ fontSize: 16 }}>
        {report.metadata.title}
      </Typography.Paragraph>
      <Typography.Title level={4}>
        {lang === "en" ? "Abstract" : "摘要"}
      </Typography.Title>
      <Typography.Paragraph style={{ fontSize: 16 }}>
        {report.metadata.abstract}
      </Typography.Paragraph>
      <div className={styles.details}>
        {report.metadata.author && (
          <div>
            <Typography.Title level={4}>
              {lang === "en" ? "Authors" : "作者"}
            </Typography.Title>
            <Typography.Paragraph style={{ fontSize: 16 }}>
              {report.metadata.author.map((it, idx) => {
                return (
                  it.full_name +
                  (idx + 1 != report.metadata.author.length ? ", " : "")
                );
              })}
            </Typography.Paragraph>
          </div>
        )}
        <div>
          <Typography.Title level={4}>
            {lang === "en" ? "US Patent No" : "美国专利号"}
          </Typography.Title>
          <Typography.Paragraph style={{ fontSize: 16 }}>
            {report.metadata.id}
          </Typography.Paragraph>
        </div>
        <div>
          <Typography.Title level={4}>
            {lang === "en" ? "Publication date" : "授权日期"}
          </Typography.Title>
          <Typography.Paragraph style={{ fontSize: 16 }}>
            {moment(report.metadata.date_published).format("YYYY-MM-DD")}
          </Typography.Paragraph>
        </div>
      </div>
    </Typography>
  );
};

const DimensionalAnalysis = ({ report, info, full, showPayModal, lang }) => {
  const draw = () => {
    if (report) {
      const chartDom = document.getElementById("quintuple");
      if (chartDom) {
        drawChart(
          chartDom,
          [
            {
              value: [
                report.rating?.novelty || 10,
                report.rating?.practicality || 10,
                report.rating?.clarity || 10,
                report.rating?.protection || 10,
                report.rating?.market || 10,
              ],
            },
          ],
          !!info.fullResult,
          lang
        );
      }
    }
  };
  React.useEffect(() => {
    // setTimeout(draw, 300);
  }, []);
  return (
    <Typography className={styles.fiveImg}>
      {/* <div style={{ height: 300, filter: !full && "blur(2px)" }}>
        <div
          id="quintuple"
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        />
      </div> */}
      {!full && (
        <div className={styles.shade}>
          {info.fullSupport === 1 && !info.payId && (
            <Button onClick={showPayModal} type="primary">
              View the full report
            </Button>
          )}
          {info.fullSupport === 2 && (
            <Alert
              type="error"
              message={
                lang == "en"
                  ? "This patent does not yet support novelty report generation. We are working on it."
                  : "该论文暂不支持生成创新性报告，我们正在努力开发。"
              }
              banner
            />
          )}
          {(info.payId || !info.fullSupport) && (
            <Spin
              tip={
                lang == "en"
                  ? "Analyzing the content of the paper... Please wait patiently as this process may take about 10 minutes."
                  : "正在分析文章内容，请耐心等待。此过程可能需要约10分钟。"
              }
              style={{ marginTop: "-60px" }}
            >
              <div style={{ width: "300px" }} />
            </Spin>
          )}
        </div>
      )}
      {full && (report.report_ENG || report.report) && (
        <Md
          md={
            lang === "en"
              ? report.report_ENG || report.report
              : report.report || report.report_ENG
          }
        />
      )}
    </Typography>
  );
};

const SimpleReport = ({ simpleReport, info, showPayModal, lang, navIds }) => {
  return (
    <div>
      <div className={styles.paperTitle}>
        <span className={styles.doi}>
          PATENT:&nbsp;{simpleReport.metadata.id}
        </span>
        <h1>{simpleReport.metadata.title}</h1>
        <Typography.Paragraph>
          {simpleReport.metadata.author &&
            simpleReport.metadata.author.map((it, idx) => {
              return (
                it.full_name +
                (idx + 1 != simpleReport.metadata.author.length ? ", " : "")
              );
            })}
        </Typography.Paragraph>
        <Typography.Paragraph>
          {simpleReport.metadata.owner};&nbsp;
          {simpleReport.metadata.publication_type};&nbsp;
          {moment(simpleReport.metadata.date_published).format("YYYY-MM-DD")}
        </Typography.Paragraph>
      </div>
      <div className={styles.paperCon} style={{ padding: 20 }}>
        <NavInfo checked={1} lang={lang} navIds={navIds} />
        <div id={navIds[0]} className={styles.cons}>
          <BasicInfo report={simpleReport} lang={lang} />
        </div>
        <NavInfo checked={2} lang={lang} navIds={navIds} />
        <div id={navIds[0]} className={styles.cons}>
          <DimensionalAnalysis
            info={info}
            report={simpleReport}
            full={false}
            showPayModal={showPayModal}
            lang={lang}
          />
        </div>
      </div>
    </div>
  );
};

const NavInfo = ({ checked, lang, navIds }) => {
  const navTabs = [
    { en: "Basic Article Info", cn: "文章基本信息" },
    { en: "Novelty Report", cn: "原创性报告" },
  ];
  const smoothRoll = (id) => {
    let anchorElement = document.getElementById(id);
    if (anchorElement) {
      anchorElement.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <div className={styles.nav}>
      <div className={styles.left}>
        {navTabs.map((tab, index) => {
          return (
            <span
              key={Math.random()}
              className={checked == index + 1 ? styles.checked : undefined}
              onClick={() => smoothRoll(navIds[index])}
            >
              {lang == "en" ? tab.en : tab.cn}
            </span>
          );
        })}
      </div>
    </div>
  );
};
