import axios from "axios";
import { message } from "antd";
import { goto, hideLoading, showLoading } from "./utils";

export const REST_HOST = "https://api.spider-matrix.win";
export const WS_HOST = "wss://api.spider-matrix.win";

axios.defaults.baseURL = REST_HOST;
axios.defaults.timeout = 60000;

const USER_ID_KEY = "X-User-Id";
const TOKEN_KEY = "X-Token";

window.token = localStorage.getItem(TOKEN_KEY);
window.userId = localStorage.getItem(USER_ID_KEY);

axios.interceptors.response.use((resp) => {
  const _token = resp.headers.get(TOKEN_KEY);
  const _userId = resp.headers.get(USER_ID_KEY);
  if (_token && _token !== window.token) {
    window.token = _token;
    localStorage.setItem(TOKEN_KEY, _token);
  }
  if (_userId && _userId !== window.userId) {
    window.userId = _userId;
    localStorage.setItem(USER_ID_KEY, _userId);
  }
  return resp;
});

axios.interceptors.request.use((req) => {
  req.headers["Content-Type"] =
    req.headers["Content-Type"] || "application/json";
  // req.headers["Access-Control-Allow-Origin"] = "*" // 解决react-pdf.js获取oss资源跨域问题
  if (window.token) {
    req.headers[TOKEN_KEY] = window.token;
  }
  if (window.userId) {
    req.headers[USER_ID_KEY] = window.userId;
  }
  return req;
});

export async function request({
  url,
  params,
  data,
  method,
  headers,
  onSuccess,
  onFail,
  loading = true,
  ignoreError = false,
  onUploadProgress,
  isJson = false,
}) {
  loading && showLoading();
  axios({
    url,
    params,
    data,
    method,
    headers,
    onUploadProgress,
  })
    .then((res) => {
      const { code, errMsg, data, detail } = res.data;
      if (detail) {
        console.error(detail);
      }
      if (code) {
        if (code === 10043) {
          window.token = null;
          localStorage.removeItem(TOKEN_KEY);
          window.userId = null;
          localStorage.removeItem(USER_ID_KEY);
          onFail && onFail(code, errMsg);
          if (url !== "/user/info") {
            goto("/");
          }
        } else {
          if (!ignoreError) {
            message.error(errMsg);
          } else {
            onFail && onFail(code, errMsg);
          }
        }
      } else {
        if (isJson) {
          onSuccess && onSuccess(res.data);
        } else {
          onSuccess && onSuccess(data);
        }
      }
      loading && hideLoading();
    })
    .catch((err) => {
      console.error(err);
      onFail ? onFail() : message.error("Network error");
      loading && hideLoading();
    });
}

export function get(props) {
  return request({ ...props, method: "get" });
}

export function post(props) {
  return request({ ...props, method: "post" });
}
