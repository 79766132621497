// 引入创建容器方法
import { createStore } from "redux";
import { common } from "./modules/common"
import { userInfo } from "./modules/userInfo"
// 初始化状态
const initState = {
    common,
};

// reducer处理action逻辑
const reducer = (state = initState, action) => {
    // console.log('reducer:', state, action)
    // 每次提交改变返回的都是一个新状态
    let newState = { ...state };
    // console.log('newState[action.type]:', action)
    switch (action.type) {
        case "common":
            newState[action.type] = { ...newState[action.type], ...action };
            return newState;
        default:
            return state;

    }
};

// 创建一个store容器
export const store = createStore(reducer);


// console.log('ssss', store.getState())
