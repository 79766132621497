import React from "react";
import { useParams } from "react-router-dom";
import Paper from "../paper/paper";
import Patent from "../patent/patent";
import { doiReg, patentReg } from "../../constant";

export default function Search({ user, example, lang }) {
  const params = useParams();
  const key = decodeURIComponent(params.key);

  if (doiReg.test(key)) {
    return <Paper user={user} example={example} doi={key} lang={lang} />;
  } else if (patentReg.test(key)) {
    return <Patent user={user} example={example} patent={key} lang={lang} />;
  } else {
    window.location.href = "/";
  }
}
