import React from "react";
import styles from "./innovate.scss";
import { Alert, Button, Collapse, message } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { get, post } from "~/request";
import Md from "~/components/md/md";
import PayModal from "~/components/pay/payModal";
import axios from "axios";
import { innovateKey } from "../../constant";
import { store } from "~/store";
import { payWithFree } from "~/components/pay/free";

export default function PaperInnovation({ info, lang }) {
  const [activeKey, setActiveKey] = React.useState(["0"]);
  const [ideas, setIdeas] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  const genIdeas = () => {
    setLoading(true);
    axios({
      url: `https://www.knowledgeworks.chat/api/innovation/paper/idea`,
      headers: { "Content-Type": "application/json" },
      method: "post",
      data: {
        ...info,
      },
      timeout: 300000,
    }).then(({ data: { ideas } }) => {
      console.log(ideas);
      setLoading(false);
      setIdeas(ideas);
    });
    // post({
    //   url: "/innovation/paper/ideas",
    //   data: {
    //     ...info,
    //   },
    //   onSuccess: (ideas) => {
    //     console.log(ideas);
    //     setLoading(false);
    //     setIdeas(ideas);
    //   },
    // });
  };

  return (
    <div className={styles.innovate}>
      <h2>{lang == "en" ? "Innovate" : "创新"}</h2>
      <strong>
        {lang == "en"
          ? "Innovate ideas and methods from the method submitted, and choose one for execution."
          : "从提交的方法中创新思路和方法，选择一种进行实施。"}
      </strong>
      <span>
        <strong>{lang == "en" ? "Experiments" : "实验"}:&nbsp;</strong>
        {info.method || info.doi}
      </span>
      {ideas === null && (
        <Button onClick={genIdeas}>{lang == "en" ? "Innovate" : "创新"}</Button>
      )}
      <div className={styles.loadingTip}>
        {loading && (
          <div className={styles.loading}>
            <LoadingOutlined />
            {lang == "en"
              ? "Generating innovation... Please wait patiently as this process may take about 5 minutes."
              : "正在生成创新思路中，请耐心等待。此过程可能需要约5分钟。"}
          </div>
        )}
        {ideas !== null && (
          <Alert
            message={`✔️ ${lang == "en" ? "Ideas generated." : "构思已生成。"}`}
            type="success"
          />
        )}
      </div>

      {ideas !== null && ideas.length > 0 && (
        <Collapse
          activeKey={activeKey}
          onChange={(keys) => setActiveKey(keys)}
          ghost
          expandIconPosition="end"
          size="large"
        >
          {ideas.map((idea, idx) => (
            <Collapse.Panel
              key={`${idx}`}
              header={<div>{lang == "en" ? idea.title : idea.title_CHN}</div>}
            >
              <Idea info={info} idea={idea} lang={lang} />
            </Collapse.Panel>
          ))}
        </Collapse>
      )}
    </div>
  );
}

const Idea = ({ idea, info, lang }) => {
  const [gened, setGened] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [isActive, setIsActive] = React.useState(false);
  const [payModalVisible, setPayModalVisible] = React.useState(false);

  const genMethod = ({ externalOrderId, payMethod }) => {
    setLoading(true);
    console.log(externalOrderId, payMethod);
    axios({
      url: "https://www.knowledgeworks.chat/api/innovation/paper/gen_method",
      method: "post",
      data: {
        ...info,
        idea,
      },
      timeout: 300000,
    }).then(({ data }) => {
      // console.log(data);
      setLoading(false);
      setIsActive(false);
      setGened(data);
    });
  };
  const generateMethod = () => {
    // console.log("🚀 ~ generateMethod ~ isActive:", isActive)
    if (isActive) return
    setIsActive(true);
    get({
      url: "/search/get/count",
      onSuccess(times) {
        if (times > 0) {

          get({
            url: "/search/minus",
            onSuccess(res) {
              if (res >= 0) {
                payWithFree({
                  data: {
                    payItem: 3,
                    ext: JSON.stringify({ ...info, idea })
                  },
                  onSuccess: () => genMethod({ ...idea, ...info }),
                })
                store.dispatch({
                  type: "common",
                  freeTimes: times - 1
                });
              }
              // setIsActive(false);
            }

          })
        } else {
          setPayModalVisible(true);
          // setIsActive(false);
        }

      }
    })

  }
  return (
    <div className={styles.idea}>
      <span>
        <strong>{lang == "en" ? "Details" : "详情"}:&nbsp;</strong>
        {lang == "en" ? idea.detail : idea.detail_CHN}
      </span>
      {!gened && (
        <Button
          onClick={generateMethod}
        >
          {lang == "en" ? "Generate method" : "生成方法"}
        </Button>
      )}
      <div className={styles.loadingTip}>
        {loading && (
          <div className={styles.loading}>
            <LoadingOutlined />
            {lang == "en"
              ? "Generating method... Please wait patiently as this process may take about 5 minutes."
              : "正在生成方法中，请耐心等待。此过程可能需要约5分钟。"}
          </div>
        )}
      </div>
      {gened && <Md md={lang == "en" ? gened.method : gened.method_CHN} />}
      <PayModal
        payItem={3}
        postData={{ ext: JSON.stringify({ ...info, idea }) }}
        onSuccess={genMethod}
        visible={payModalVisible}
        setVisible={setPayModalVisible}
        lang={lang}
      />
    </div>
  );
};
