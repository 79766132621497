import React from "react";
import { Button, Modal } from "antd";

export default function Disclaimer({
  isModalOpen,
  onOk,
  onCancel,
  type,
  lang,
}) {
  return (
    <Modal
      title={
        type != "home" && lang == "en"
          ? "Please confirm the disclaimer"
          : "免责声明"
      }
      open={isModalOpen}
      onCancel={onCancel}
      onOk={onOk}
      footer={
        <Button key="submit" type="primary" onClick={onOk}>
          {type != "home" && lang == "en" ? "I agree" : "我同意"}
        </Button>
      }
    >
      {lang == "cn" ? <Disclaimerdatacn /> : <Disclaimerdata type={type} />}
    </Modal>
  );
}

const Disclaimerdata = ({ type }) => {
  return (
    <React.Fragment>
      Thank you for using our service. We are committed to providing you with
      the most accurate and objective report content. However, we also want to
      make it clear that our company's report represents only our own position
      and viewpoint, and does not represent the position of any other party.
      <br />
      All information, data, conclusions and recommendations provided by this
      website and the company are for reference only. You should make your own
      judgment and decisions. We will not be liable for any direct or indirect
      losses incurred by any user due to the use or reliance on this website or
      the company's reports.
      <br />
      We remind you that it is prohibited to sell and personally disseminate
      reports, as well as to sell and share accounts. We will take measures such
      as banning accounts for such behaviors. We hope that users can understand
      and abide by this regulation to maintain the health and order of our
      platform.
      <br />
      In addition, our reports may contain links to third-party websites. These
      links are provided for your reference only, and we are not responsible for
      the accuracy, completeness, or legality of these websites.
      <br />
      {type == "home"
        ? "Please abide by laws, regulations, and ethical norms when using our services, respect the rights and interests of others, and work together to maintain a good online environment. Thank you again for your understanding and support."
        : "Please export the report in a timely manner after payment. This platform does not record your payment history, and will not be responsible for any consequences after exiting the report interface. When using our services, please abide by laws, regulations, and ethical norms, respect the rights and interests of others, and work together to maintain a healthy online environment. Thank you again for your understanding and support."}
      <br />
    </React.Fragment>
  );
};

const Disclaimerdatacn = () => {
  return (
    <React.Fragment>
      感谢您使用我们的服务。我们致力于为您提供最准确、最客观的报告内容。然而，我们也要明确指出，本公司的报告仅代表我们自己的立场和观点，并不代表其他任何方面的立场。
      <br />
      本网站和本公司所提供的一切信息、数据、结论和建议仅供参考，您应自行判断并做出决策。我们不会对任何用户因使用或依赖本网站或本公司的报告而产生的任何直接或间接损失承担责任。
      <br />
      特别提醒您，禁止销售和个人传播报告，禁止销售和共享账户。我们对此类行为将采取封禁账户等措施。我们希望用户能够理解和遵守这一规定，以保持我们平台的健康和秩序。
      <br />
      此外，我们的报告可能会包含链接到第三方网站的内容。这些链接仅供您参考之用，我们不对这些网站的准确性、完整性或合法性负责。
      <br />
      付费后请及时导出报告，本平台不记录您的付费历史，退出报告界面后概不负责。请您在使用我们的服务时，遵守法律法规和道德规范，尊重他人的权利和利益，共同维护一个良好的网络环境。再次感谢您的理解和支持。
    </React.Fragment>
  );
};
