import React, { useEffect, useState } from "react";
import styles from "./head.scss";
import { ossServer } from "~/constant";
import { Button, Dropdown } from "antd";
import { LogoutOutlined, TranslationOutlined } from "@ant-design/icons";
import { clearToken } from "~/utils";
import { Link } from "react-router-dom";
import { store } from "~/store";
import { get } from "../../request";
export default function Head({ user, onClickLogin, setUser, lang, setLang }) {
  const [times, setTimes] = useState(0);
  useEffect(() => {
    get({
      url: "/search/get/count",
      onSuccess(res) {
        if (res) {
          setTimes(res);
          store.dispatch({
            type: "common",
            freeTimes: res
          });
        }

      }
    })
    // 监控dispatch提交的action改变
    store.subscribe(() => {
      const { common, } = store.getState();
      // console.log("🚀 ~ file: App.jsx:19 ~ store.subscribe ~ settings:", common)
      setTimes(common?.freeTimes);
    });
  }, []);

  return (
    <div className={styles.head}>
      <Link className={styles.logo} to="/">
        <img src={`${ossServer}/web/logo.ico`} />
        <h3>Spider Matrix</h3>
      </Link>
      <div className={styles.right}>

        <span>剩余免费次数：<span style={{ color: '#1677ff' }}>{times}</span>次</span>
        {/* <Link to='/list'>
          History
        </Link> */}
        {/* <UserInfo user={user} onClickLogin={onClickLogin} setUser={setUser} /> */}
        <a href="https://racing.spider-matrix.win/" target="_blank">
          {lang == "en" ? "Racing" : "竞赛"}
        </a>
        <a href="https://blog.spider-matrix.win/" target="_blank">
          {lang == "en" ? "Blog" : "博客"}
        </a>
        <TranslationOutlined
          style={{ fontSize: 17, float: "right" }}
          onClick={() => {
            lang == "en" ? setLang("cn") : setLang("en");
          }}
        />
      </div>
    </div>
  );
}

function UserInfo({ user, onClickLogin, setUser }) {
  const logout = () => {
    clearToken();
    setUser(null);
    window.location.href = "/";
  };
  const items = [
    {
      key: "2",
      label: (
        <div onClick={logout}>
          <LogoutOutlined style={{ color: "#1677ff" }} />
          &nbsp;&nbsp;Sign out
        </div>
      ),
    },
  ];

  return (
    <Dropdown
      menu={{ items, selectable: true, defaultSelectedKeys: ["2"] }}
      placement="bottom"
      disabled={!user}
    >
      <div className={styles.user} onClick={onClickLogin}>
        {user ? (
          <>
            <img src={`${ossServer}/web/user.png`} alt="user" />
            {user?.phone || user?.email}
          </>
        ) : (
          <Button type="primary">Login</Button>
        )}
      </div>
    </Dropdown>
  );
}
