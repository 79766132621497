import React from "react";
import Vditor from "vditor";

export default function Md({md}) {
  const id = Math.random().toString()
  React.useEffect(() => {
    Vditor.preview(document.getElementById(id), md, {
      hljs: {
        style: "github",
      },
    })
  }, [md])
  return <div id={id}/>
}