import React, { useEffect, useState } from "react";
import styles from "./index.scss";
import {
  Button,
  ConfigProvider,
  Input,
  message,
  Modal,
  Select,
  Space,
  Spin,
  Radio,
  Popover,

} from "antd";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { post } from "../../request";
import { doiReg, innovateKey, ossServer, patentReg } from "../../constant";
import Disclaimer from "../../components/disclaimer/disclaimer";
import axios from "axios";
import { journals, journalTitle } from "../../assets/journalData";

import moment from "moment/moment";
import PayModal from "~/components/pay/payModal";
const { TextArea } = Input;

const exampleDoi = [
  "10.1021/acs.jpclett.3c01668",
  // "10.1038/s41598-018-25878-8",
  // "10.1038/s41598-021-86876-x",
  // "10.3390/en11112928",
  // "10.1002/agt2.182",
  // "10.1016/j.virol.2019.11.009",
];

const examplePatent = ["US20190280334A1"];

const exampleInnonate =
  "Synthesis of CuS NCs. Hexagonal plate-like CuS NCs were synthesised according to a previous study14. A mixture of copper (I) acetate (0.123 g, 1 mmol) and oleylamine (10 mL) was degassed at 160 °C for 30 min. Subsequently, a solution of sulphur (0.048 g, 1.5 mmol) in 1-octadecene (15 mL) was injected rapidly into the mixture under a nitrogen atmosphere and stirred for 10 min. The resulting product was purified by adding an ethanol-hexane (v = 1:1) mixed solvent to the solution, centrifuging twice, and then redispersing the precipitate in hexane.";

export default function Home({ user, setLoginOpen, lang }) {
  const location = useLocation();
  const navigate = useNavigate();
  const [searchKey, setSearchKey] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [spinning, setSpinning] = useState(false);
  const [searchType, setSearchType] = useState("doi");
  const [placeHolder, setPlaceHolder] = useState("10.1021/acs.jpclett.3c01668");
  const [exampleText, setExampleText] = useState("Example doi");
  const [searchReg, setSearchReg] = useState(doiReg);
  const [txtValue, setTxtValue] = useState("idea");
  const [text, setText] = useState(""); // idea method
  const [payModalVisible, setPayModalVisible] = useState(false);

  useEffect(() => {
    switch (searchType) {
      case "doi":
        setPlaceHolder("10.1021/acs.jpclett.3c01668");
        setExampleText(lang == "en" ? "Example doi" : "DOI示例");
        setSearchReg(doiReg);
        break;
      case "patent":
        setPlaceHolder("US20190280334A1");
        setExampleText(lang == "en" ? "Patent number example" : "专利号示例");
        setSearchReg(patentReg);
        break;
      case "innovate":
        setPlaceHolder("10.1038/s41467-023-40153-9");
        setSearchReg(doiReg);
        break;
      case "keyword":
        setPlaceHolder("Perovskite");
        break;
      default:
        break;
    }
  }, [searchType, lang]);

  

  const search = () => {
    if (user) {
      if (searchType == "keyword") {
        axios({
          url: `https://www.knowledgeworks.chat/api/get_doi_by_keyword?keyword=${searchKey}`,
          headers: { "Content-Type": "application/json" },
          method: "get",
        }).then(({ data }) => {
          navigate(`/search/${encodeURIComponent(data[0])}`);
        });
      } else {
        if (searchReg.test(searchKey)) {
          setSpinning(true);
          post({
            url: "/search",
            ignoreError: true,
            data: {
              [searchType]: searchKey,
            },
            onSuccess: (data) => {
              console.log(data);
              setSpinning(false);
              navigate(
                `/search/${encodeURIComponent(searchKey)}${location.search}`
              );
              
            },
            onFail: (code, msg) => {
              setSpinning(false);
              if (code === 10040) {
                message.error(`Invalid ${searchType}`);
              } else if (code === 10080) {
                setIsModalOpen(true);
              }
            },
          });
        } else {
          message.warning(
            `Please check that the ${searchType} entered is correct`
          );
        }
      }
    } else {
      message.warning("Please login before operation");
      setLoginOpen(true);
    }
  };

  const onDisclaimerConfirmed = () => {
    post({
      url: "/user/disclaimer/confirm",
      data: {},
      onSuccess: (res) => {
        setIsModalOpen(false);
        search();
      },
    });
  };

  const searchOptions = [
    {
      value: "doi",
      label: "DOI",
    },
    {
      value: "keyword",
      label: "Keyword",
    },
    {
      value: "patent",
      label: "Patent",
    },
    {
      value: "innovate",
      label: "Innovate",
    },
  ];

  // const txtOptions = [
  //   {
  //     label: "DOI (s)",
  //     value: "doi",
  //   },
  //   {
  //     label: "Method Section",
  //     value: "method",
  //   },
  //   {
  //     label: "General Idea",
  //     value: "idea",
  //   },
  // ];

  const changeType = (e) => {
    console.log(e.target.value);
    setTxtValue(e.target.value);
  };

  const explore = () => {
    const method = text.trim();
    console.log(method, text);
    if (method) {
      localStorage.setItem(
        innovateKey,
        JSON.stringify({ type: "paper", method })
      );
      setTimeout(() => (window.location.href = "/innovate"), 100);
    }
  };

  const content = (
    <div className={styles.journals}>
      {journalTitle}
      {Object.keys(journals).map((key) => {
        return (
          <div key={key}>
            <h4>{key}</h4>
            {journals[key].map((item, index) => {
              return (
                <div key={index} className={styles.journalTxt}>
                  <a href={item.url}>
                    {item.name}
                    {index != journals[key].length - 1 && ", "}
                  </a>
                  &nbsp;
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
  return (
    <div className={styles.home}>
      <div className={styles.left}>
        <h1>
          {lang == "en" ? "Paper&Patent Innovation" : "论文&专利思路创新"}
        </h1>
        <div className={styles.search}>
          {/* {searchType == "innovate" && (
            <Radio.Group
              options={txtOptions}
              onChange={changeType}
              value={txtValue}
              size="large"
            />
          )} */}
          <Space.Compact
            style={{
              width: "100%",
            }}
          >
            <Select
              style={{ width: 140, marginRight: 5 }}
              size="large"
              value={searchType}
              options={searchOptions}
              onChange={(type) => setSearchType(type)}
            />
            {searchType != "innovate" && (
              <React.Fragment>
                <Input
                  size="large"
                  placeholder={placeHolder}
                  value={searchKey}
                  onChange={(e) => setSearchKey(e.target.value)}
                />
                <Button size="large" type="primary" onClick={search}>
                  {lang == "en" ? "Search" : "搜索"}
                </Button>
              </React.Fragment>
            )}
            {searchType == "innovate" &&
              (txtValue == "doi" ? (
                <div className={styles.inputs}>
                  <label>
                    {lang == "en"
                      ? "Input paper DOI for automatic method extraction:"
                      : "输入论文DOI自动获取："}
                  </label>
                  <Input placeholder={placeHolder} />
                  {[0, 1, 2, 3, 4].map((it) => {
                    return (
                      <React.Fragment key={it}>
                        <label>
                          {lang == "en"
                            ? "Input more DOI (optional):"
                            : "输入更多DOI（可选）："}
                        </label>
                        <Input />
                      </React.Fragment>
                    );
                  })}
                  <label>
                    Only papers from publishers{" "}
                    <b>
                      <i>ACS, Elsevier, MDPI, Nature</i>
                    </b>{" "}
                    are supported now. We will currently develop to support more
                    papers.
                  </label>
                </div>
              ) : (
                <TextArea
                  rows={10}
                  placeholder={exampleInnonate}
                  value={text}
                  onChange={(e) => setText(e.target.value)}
                />
              ))}
          </Space.Compact>
          {searchType == "innovate" && (
            <Button
              size="large"
              type="primary"
              onClick={explore}
              className={styles.btnBot}
            >
              {lang == "en" ? "Explore" : "查询"}
            </Button>
          )}
          {searchType != "polish" && searchType != "keyword" && (
            <ul>
              {searchType === "doi" && (
                <h4>
                  * Publications after 2003 and in this{" "}
                  <Popover content={content} placement="bottom">
                    <u>journal list</u>
                  </Popover>{" "}
                  are mostly supported
                </h4>
              )}
              {searchType != "innovate" && (
                <h4>
                  {exampleText}:&nbsp;&nbsp;(
                  {lang == "en"
                    ? "Click for the free full report."
                    : "点击查看免费的完整报告"}
                  )
                </h4>
              )}
              {searchType === "doi" &&
                exampleDoi.map((doi) => (
                  <React.Fragment key={doi}>
                    <Link
                      key={doi}
                      to={`/example/${encodeURIComponent(doi)}${
                        location.search
                      }`}
                    >
                      {doi}
                    </Link>
                    <br />
                  </React.Fragment>
                ))}
              {searchType === "patent" &&
                examplePatent.map((patent) => (
                  <React.Fragment key={patent}>
                    <Link
                      key={patent}
                      to={`/example/${encodeURIComponent(patent)}${
                        location.search
                      }`}
                    >
                      {patent}
                    </Link>
                    <br />
                  </React.Fragment>
                ))}
            </ul>
          )}
        </div>
      </div>

      <div className={styles.video}>
        <video
          src={`${ossServer}/web/${
            lang == "cn" ? "SpiderMatrix%20IdeaInnovation" : "EngSpiderMatrix"
          }.mp4`}
          controls
        ></video>
      </div>
      <Disclaimer
        isModalOpen={isModalOpen}
        onOk={onDisclaimerConfirmed}
        onCancel={() => {
          setIsModalOpen(false);
        }}
        type="home"
        lang={lang}
      />
      <Spin
        spinning={spinning}
        fullscreen
        tip={
          lang == "en" ? "Loading paper information..." : "正在加载文章信息..."
        }
      />
    </div>
  );
}
